import React from "react";
import { Link, useHistory } from "react-router-dom";
const logoTextImag = require("assets/rocket/rocket_text.png").default;
const Logo = (props) => {
  const history = useHistory();
  return (
    <>
      <div className="md:container md:mx-auto mt-1 mb-2">
        <div
          className="grid grid-cols-1 items-center text-xl"
          style={{ width: "100%" }}
        >
          <div>
            <div className="" align="center">
              <Link to="/">
                <img src={logoTextImag} alt="" style={{
                  height: 60
                }} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logo;
