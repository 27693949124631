/**
 * disable keyboard and mouse helper
 *
 */

export const disableViewCode = (e) => {
  window.addEventListener('keydown', (e) => {
    if (e.keyCode === 123) {
      // console.log('not alllowd: F-12');
      // alert('not alllowd: F-12');
      e.preventDefault();
      return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
      // alert('not alllowd   CTRL + Ship+ I', e.ctrlKey);
      // console.log('not alllowd: CTRL + Ship+ I ');
      e.preventDefault();
      return false;
    }
    if (e.ctrlKey && e.which === 85) {
      // alert('not alllowd: CTRL + U', e.ctrlKey);
      // console.log('not alllowd: CTRL + U ');
      e.preventDefault();
      return false;
    }
  });
};

export const disableRightClick = () => {
  window.addEventListener(`contextmenu`, (e) => e.preventDefault());
};
