import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";

import { http } from "helpers/http";
import GameSlider from "components/GameSlider";
import GameSwiper from "components/game-swiper/GameSwiper";
import Menu from "components/Menu";
import Footer from "components/Footer";
import Navbar from "components/Navbar";

export default function Games() {
  const [items, setItems] = useState([]);
  const { campId } = useParams();

  async function loadData() {
    const { data } = await http.get("/play/game");
    if (data) {
      setItems(data.data);
    }
  }

  async function loadGamesByCampId(id) {
    const { data } = await http.get(`/play/camp/${id}/games`);
    if (data) {
      setItems(data.data);
    }
  }

  useEffect(() => {
    if (campId) {
      loadGamesByCampId(campId);
    } else {
      loadData();
    }
  }, [campId]);

  async function loadBonusTimeByCampId(id) {
    const { data } = await http.get(`/play/camp/${id}/bonustimes`);
    if (data) {
      setItems(data.data);
    }
  }

  return (
    <>
      <main className="container mx-auto">
        <div className="">
          <Navbar transparent />
          <Menu />
          <div className="flex justify-center justify-items-center mt-2">
            <div className="flex-none">
              <Link to="/camps">
                <button
                  type="button"
                  className="px-3 py-1 bg-white text-gray-900 rounded-full border-solid border-1 border-black"
                >
                  เกมส์ทั้งหมด
                </button>
              </Link>
            </div>
            <div className="flex-none">
              <button
                onClick={() => loadBonusTimeByCampId(campId)}
                type="button"
                className=" ml-2 px-3 py-1 bg-gray-500 text-black rounded-full border-solid border-1 border-black"
              >
                กิจกรรมเร็ว ๆ นี้
              </button>
            </div>
          </div>
        </div>
        <section className="pt-3">
          <div align="center" className="mt-1">
            {/* {items && items.length > 0 ? <GameSwiper items={items} /> : null} */}
            {items && items.length > 0 ? <GameSlider items={items} /> : null}
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
}
