import Footer from "components/Footer";
import Navbar from "components/Navbar";

const NotFound = (props) => {
  return (
    <>
    <Navbar/>
    <main className="p-3">
    <h1>Error: 404</h1>
    <p>ไม่พบหน้า</p>
    </main>
    <Footer/>
    </>
  )
};

export default NotFound;
