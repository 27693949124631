import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AuthStore from 'stores/AuthStore';
const Navbar = observer((props) => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const authStore = useContext(AuthStore);
  const hist = useHistory();
  const doLogout = () => {
    setNavbarOpen(false);
    console.log('click logout');
    authStore.logout();
    hist.push('/login');
  };

  return (
    <>
      <nav
        className={
          (props.transparent
            ? 'top-0 absolute z-50 w-full'
            : 'relative shadow-lg bg-sky-900') +
          ' flex flex-wrap items-center justify-between px-1 py-1 '
        }
      >
        <div className='container px-4 mx-auto flex flex-wrap items-center justify-between'>
          <div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
            <Link
              onClick={() => setNavbarOpen(false)}
              className={
                (props.transparent ? 'text-white' : 'text-gray-800') +
                ' text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase'
              }
              to='/landing'
            >
              {/* <img src={Logo} alt="" className="" style={{ height: 32 }} /> */}
            </Link>
            <button
              className='cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none'
              type='button'
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i
                className={
                  (props.transparent ? 'text-white' : 'text-gray-300') +
                  ' fas fa-bars'
                }
              ></i>
            </button>
          </div>
          <div
            className={
              'lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none' +
              (navbarOpen ? ' block rounded' : ' hidden')
            }
            id='example-navbar-warning'
          >
            <ul className='flex flex-col lg:flex-row list-none mr-auto'>
              {/* <li className="flex items-center">
                <Link
                  className={
                    (props.transparent
                      ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                      : "text-gray-800 hover:text-gray-600") +
                    " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  to="/camps"
                >
                  <i
                    className={
                      (props.transparent
                        ? "lg:text-gray-300 text-gray-500"
                        : "text-gray-500") +
                      " far fa-file-alt text-lg leading-lg mr-2"
                    }
                  />{" "}
                  ค่ายเกมส์
                </Link>
              </li> */}
            </ul>

            <ul className='flex flex-col lg:flex-row list-none lg:ml-auto'>
              {authStore.isLoggedIn ? (
                <>
                  <Link
                    onClick={() => setNavbarOpen(false)}
                    className={
                      (props.transparent
                        ? 'lg:text-white lg:hover:text-gray-300 text-gray-800'
                        : 'text-gray-800 hover:text-gray-600') +
                      ' px-2 py-2 lg:py-2 flex items-center text-xs uppercase font-bold'
                    }
                    to='/camps'
                  >
                    <li className='flex items-center'>
                      <i
                        className={
                          (props.transparent
                            ? 'lg:text-gray-300 text-gray-500'
                            : 'text-gray-500') +
                          ' fa fa-gamepad text-lg leading-lg '
                        }
                      />
                      <span className='inline-block ml-2'>
                        ค่ายเกมส์
                      </span>
                    </li>{' '}
                  </Link>
                  <Link
                    onClick={() => setNavbarOpen(false)}
                    className={
                      (props.transparent
                        ? 'lg:text-white lg:hover:text-gray-300 text-gray-800'
                        : 'text-gray-800 hover:text-gray-600') +
                      ' px-2 py-2 lg:py-2 flex items-center text-xs uppercase font-bold'
                    }
                    to='#'
                  >
                    <li className='flex items-center'>
                      <i
                        className={
                          (props.transparent
                            ? 'lg:text-gray-300 text-gray-500'
                            : 'text-gray-500') +
                          ' fa fa-clock text-lg leading-lg '
                        }
                      />
                      <span className='inline-block ml-2'>
                        BONUS TIME
                      </span>
                    </li>
                  </Link>
                  <Link
                    onClick={() => setNavbarOpen(false)}
                    className={
                      (props.transparent
                        ? 'lg:text-white lg:hover:text-gray-300 text-gray-800'
                        : 'text-gray-800 hover:text-gray-600') +
                      ' px-2 py-2 lg:py-2 flex items-center text-xs uppercase font-bold'
                    }
                    to='#'
                  >
                    <li className='flex items-center'>
                      <i
                        className={
                          (props.transparent
                            ? 'lg:text-gray-300 text-gray-500'
                            : 'text-gray-500') +
                          ' fa fa-rss-square text-lg leading-lg '
                        }
                      />
                      <span className=' inline-block ml-2'>
                        ข่าวสาร
                      </span>
                    </li>{' '}
                  </Link>
                  <li className='flex items-center'>
                    <button
                      onClick={doLogout}
                      className={
                        (props.transparent
                          ? 'btn-sm bg-gray-500 text-white active:bg-gray-100'
                          : 'btn-sm bg-gray-500 text-white active:bg-pink-600') +
                        ' text-xs font-bold uppercase px-4 py-2 rounded-lg  hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3'
                      }
                      type='button'
                      style={{ transition: 'all .15s ease' }}
                    >
                      <i className='fa fa-lock mr-1'></i> ออกจากระบบ
                    </button>
                  </li>
                </>
              ) : null}

              {!authStore.isLoggedIn ? (
                <>
                  <li className='flex items-center'>
                    <Link
                      onClick={() => setNavbarOpen(false)}
                      className={
                        (props.transparent
                          ? 'bg-gray-500 text-white active:bg-gray-100'
                          : 'bg-gray-500 text-white active:bg-pink-600') +
                        ' text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3'
                      }
                      type='button'
                      style={{ transition: 'all .15s ease' }}
                      to='/login'
                    >
                      <i className='fas fa-key'></i> เข้าสู่ระบบ
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </nav>

      {!authStore.isLoggedIn ? (
        <></>
      ) : (
        <>
          {/* <span className="m-1">
            {authStore.user ? "สวัสดี: " + authStore.user.name : ""}
          </span>
          <button
            className={
              (props.transparent
                ? "bg-white text-gray-800 active:bg-gray-100"
                : "bg-red-500 text-white active:bg-pink-600") +
              " text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
            }
            type="button"
            style={{ transition: "all .15s ease" }}
            onClick={doLogout}
          >
            <i className="fas fa-lock"></i> ออกจากระบบ
          </button> */}
        </>
      )}
    </>
  );
});

export default Navbar;
