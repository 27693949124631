import React, { useContext, useEffect, useState } from "react";
import Navbar from "components/Navbar.js";
import { observer } from "mobx-react-lite";
import AuthStore from "stores/AuthStore";
import { http } from "helpers/http";
import CampSlider from "components/CampSlider";
import Footer from "components/Footer";
import Menu from "components/Menu";

const Camps = observer((props) => {
  const authStore = useContext(AuthStore);
  const [items, setItems] = useState([]);

  async function loadCamps() {
    const { data } = await http.get("/play/camp");
    if (data.data) {
      setItems(data.data);
    }
  }

  useEffect(() => {
    loadCamps();
  }, []);

  return (
    <>
      <main className="md:container md:mx-auto">
        <div className="">
          <Navbar transparent />
          <Menu />
        </div>
        <section
          className="pt-3"
          style={{
            display: "block",
            clear: "both",
          }}
        >
          {items ? <CampSlider items={items} /> : null}
        </section>
        <Footer />
      </main>
    </>
  );
});

export default Camps;
