import React, { useEffect } from "react";
import Carousel from "react-grid-carousel";
import { Link } from "react-router-dom";
const CampSlider = (props) => {
  const items = props.items;
  useEffect(() => {}, []);

  const carouselDot = ({ isActive }) => (
    <span
      style={{
        display: "inline-block",
        height: isActive ? "20px" : "15px",
        width: isActive ? "20px" : "15px",
        background: isActive ? "#ffffff" : "#000000",
        marginTop: 20,
        borderRadius: 10,
        border: "solid 2px #ffffff",
      }}
    ></span>
  );
  return (
    <>
      <div className="" style={{
        display:'block'
      }}>
        {items && items.length > 0 ? (
          <Carousel
            cols={3}
            rows={3}
            gap={10}
            mobileBreakpoint={0}
            showDots
            loop
            dot={carouselDot}
          >
            {items.map((val, i) => (
              <Carousel.Item key={i}>
                <div align="center">
                  <Link to={"/camps/" + val.id + "/games"}>
                    {/* <Link to={"/bonustime"}> */}
                    <img
                      src={val.image_cover ? val.image_cover.thumbnail : null}
                      alt={val.name}
                      style={{ width: 100 }}
                    />
                    <div>{/* <span>{val.name}</span> */}</div>
                  </Link>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        ) : null}
      </div>
    </>
  );
};

export default CampSlider;
