import { parse } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import AuthStore from 'stores/AuthStore';

const Menu = observer((props) => {
  const authStore = useContext(AuthStore);
  const history = useHistory();
  return (
    <>
      {authStore.isLoggedIn ? (
        <div className='mx-2'>
          <div
            className='clear-both flex justify-center justify-items-center text-white'
            style={{
              maxWidth: 400,
              background: '#002249',
              borderRadius: 10,
              textAlign: 'center',
              margin: '0 auto',
            }}
          >
            <div className='text-center' align='center'>
              <button type='button' className='py-1 w-full block'>
                <i className='fa fa-user-circle'></i>{' '}
                {authStore.user ? authStore.user.name : ''}
              </button>
            </div>
            <div>
              <div
                className='py-1'
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                |
              </div>
            </div>
            <div
              align='center'
              className='text-center'
              style={{
                color:
                  authStore.timeoutInMinutes <= 15 ? 'rgb(255,0,0)' : 'rgb(255,255,255)',
              }}
            >
              <button type='button' className='py-1 w-full block'>
                <i className='fa fa-clock'></i> {authStore.showTimeoutCountdown}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
});

export default Menu;
