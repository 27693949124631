import Footer from "components/Footer";
import { http } from "helpers/http";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import AuthStore from "stores/AuthStore";
import { config } from "../config";

const logoTextImag = require("assets/rocket/rocket_text.png").default;
const logoCartoonImag = require("assets/rocket/rocket_cartoon.png").default;

const Login = observer((props) => {
  const authStore = useContext(AuthStore);
  const history = useHistory();
  const [code, setCode] = useState();
  const [message, setMessage] = useState("");
  const codeRef = useRef();
  useEffect(() => {
    codeRef.current.focus();
    if (authStore.isLoggedIn) {
      history.push("/camps");
    }
  }, []);

  const doLogin = () => {
    setMessage("");
    if (code) {
      http
        .post("/play/activate", {
          code: code,
        })
        .then(({ data }) => {
          console.log(data);
          if (data) {
            const token = data.data.code;
            const user = data.data.user;
            authStore.setToken(token, data.data.timeout_at);
            authStore.setUser({
              name: user,
            });
            history.push("/camps");
          }
        })
        .catch((err) => {
          if (err.response && err.response.status < 500) {
            const data = err.response.data;
            setMessage(data.message);
          }
        });
    } else {
      codeRef.current.focus();
      setMessage("โปรดกรอกโค้ด");
    }
    return false;
  };

  const messageError = () => {
    if (message) {
      return (
        <div className="text-orange-700 p-1" role="alert">
          <p>{message}</p>
        </div>
      );
    }
  };

  const OnKeyUp = (e) => {
    if (e.keyCode === 13) {
      doLogin();
    }
  };
  return (
    <>
      <main>
        <section>
          {/* /////////////////////// */}
          <div className="login-container">
            <div className="login-bg" style={{}}>
              <br />
              <div className="text-center" align="center">
                <img
                  src={logoCartoonImag}
                  alt="rocket"
                  className="text-center"
                  style={{
                    display: "inline-block",
                    textAlign: "center",
                  }}
                />
                <img
                  src={logoTextImag}
                  alt="rocket"
                  className="text-center mx-auto"
                  style={{
                    display: "inline-block",
                    textAlign: "center",
                    marginTop: -35,
                  }}
                />
              </div>

              <div className="text-center mt-2">
                <input
                  ref={codeRef}
                  onChange={(e) => setCode(e.target.value)}
                  type="text"
                  className="py-2 px-2 text-center focus:outline-none focus:ring text-white"
                  placeholder="กรอกโค้ด"
                  style={{
                    transition: "all .15s ease",
                    background: "#000000",
                    border: "1px solid #074D9F",
                    boxSizing: "border-box",
                    borderRadius: "10px",
                    width: 230,
                  }}
                  onKeyUp={OnKeyUp}
                />
                {messageError()}
                {/* {authStore.errMessage ? (
                  <div className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white">
                    {authStore.errMessage}
                  </div>
                ) : null} */}
              </div>
              <div className="text-center mt-4">
                <button
                  className="bg-orange-700 text-black active:bg-gray-700 text-sm font-bold uppercase px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  onClick={doLogin}
                  style={{
                    transition: "all .15s ease",
                    width: 160,
                    background:
                      "linear-gradient(92.64deg, #BD9128 0%, #D7B259 20.81%, #FBDF9E 49.73%, #DBB762 74.09%, #BD9128 97.44%)",
                    borderRadius: 10,
                  }}
                >
                  เข้าสู่ระบบ
                </button>
                {/* <div className="text-white">
                  <button type="button" className="underline text-white">
                    ขอโค้ดรวมสูตรลับ
                  </button>
                </div> */}
              </div>
            </div>

            <div></div>
          </div>
          {/* /////////////////////// */}
        </section>
      </main>
    </>
  );
});

export default Login;
