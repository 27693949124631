import { config } from 'config';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-grid-carousel';
import CountUp from 'react-countup';
import imgComingsoon from '../assets/rocket/icon_comingsoon.png';
import imgMaintenance from '../assets/rocket/icon_maintenance.png';

const GameSlider = (props) => {
  const items = props.items;
  useEffect(() => {}, []);
  const [showPlayButton, setShowPlayButton] = useState('');


  const checkLink = (item, url, target = '_blank') => {
    if (item.game_status === 'active' || item.game_status === 'open') {
      setShowPlayButton(item.id);
      const el = document.getElementById(`#game${item.id}`);
      console.log(el);
    } else {
      return false;
    }
  };

  const clickPlay = (item, target = '_blank') =>
    window.open(config.linkPlayGame, target);
  const clickContact = (item, target = '_blank') =>
    window.open(config.linkLine, target);

  const styleFrame = {
    position: 'relative',
    width: 105,
    marginBottom: 7,
  };

  const styleContent = {
    paddingTop: 10,
    width: 105,
    height: 155,
  };

  const styleFilter = {
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(1,1,1,.9)',
    zIndex: 5,
    borderRadius: 10,
  };

  const stylePercent = {
    color: '#01c1c4',
    position: 'absolute',
    bottom: '7%',
    left: 0,
    right: 0,
    fontWeight: 'bold',
  };

  const carouselDot = ({ isActive }) => (
    <span
      style={{
        display: 'inline-block',
        height: isActive ? '20px' : '15px',
        width: isActive ? '20px' : '15px',
        background: isActive ? '#ffffff' : '#000000',
        marginTop: 20,
        borderRadius: 10,
        border: 'solid 2px #ffffff',
      }}
    ></span>
  );

  const colorPercent = (item) => {
    const point = item.percent;
    if (point >= 90) {
      return '#00ffff';
    } else if (point >= 80) {
      return '#ffe500';
    } else {
      return '#ff7171';
    }
  };
  return (
    <>
      {items && items.length > 0 ? (
        <Carousel
          gap={5}
          cols={3}
          rows={3}
          mobileBreakpoint={0}
          showDots
          dot={carouselDot}
        >
          {items
            ? items.map((val, i) => (
                <Carousel.Item key={i}>
                  <div align='center' className='game-bg' style={styleFrame}>
                    <div style={styleContent}>
                      <div
                        className='cursor-pointer'
                        onClick={() => checkLink(val, config.linkPlayGame)}
                        alt={val.name}
                      >
                        <img
                          src={
                            val.image_cover ? val.image_cover.thumbnail : null
                          }
                          style={{
                            width: 80,
                          }}
                          alt={val.name}
                        />
                        <div
                          className='text-white block'
                          style={{
                            fontSize: 11,
                            lineHeight: '19px',
                          }}
                        >
                          {val.name}
                        </div>
                        <div className='text-xl' style={stylePercent}>
                          {val.percent && val.percent > 0 ? (
                            <CountUp
                              start={0}
                              end={val.percent}
                              duration={1.5}
                              suffix='%'
                              style={{
                                color: colorPercent(val),
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      {val.game_status === 'comingsoon' ? (
                        <span
                          className='cursor-pointer'
                          style={styleFilter}
                          onClick={() => setShowPlayButton('')}
                        >
                          <div className='text-white mt-5' align='center'>
                            <img
                              src={imgComingsoon}
                              alt='comingsoon'
                              style={{
                                backgroundColor: 'rgba(1,1,1,.8)',
                              }}
                            />
                            เร็ว ๆ นี้
                          </div>
                        </span>
                      ) : null}

                      {val.game_status === 'maintenance' ? (
                        <span
                          className='cursor-pointer'
                          style={styleFilter}
                          onClick={() => setShowPlayButton('')}
                        >
                          <div className='text-white mt-5' align='center'>
                            <img
                              src={imgMaintenance}
                              alt='comingsoon'
                              style={{
                                backgroundColor: 'rgba(1,1,1,.8)',
                              }}
                            />
                            ปิดปรับปรุง
                          </div>
                        </span>
                      ) : null}
                      {showPlayButton == val.id ? (
                        <span
                          id={'game' + val.id}
                          align='center'
                          style={styleFilter}
                        >
                          <div className='flex flex-col justify-items-center'>
                            <div
                              className='text-black rounded-lg'
                              style={{
                                background: 'rgb(255,255,255, 1)',
                                fontWeight: 'bold',
                                margin: 5,
                                bottom: 0,
                                top: 0,
                                border: 'solid 2px #ffffff',
                              }}
                            >
                              <div
                                className='text-black  rounded-lg'
                                style={{
                                  background:
                                    'radial-gradient(100% 100% at 45.84% 0%, #EFFFFF 0%, #A7FFFF 59.16%, #00FFFF 100%)',
                                  border: 'inset 2px black',
                                  height: 140,
                                }}
                              ></div>
                              <a
                                onClick={() => clickPlay(val)}
                                alt='play'
                                className='cursor-pointer'
                                style={{
                                  position: 'absolute',
                                  zIndex: 15,
                                  top: '15%',
                                  left: 0,
                                  right: 0,
                                }}
                              >
                                <i className='fa fa-gamepad fa-4x'></i>
                                <div
                                  style={{
                                    fontSize: '1.7em',
                                    textShadow: '2px 2px #ffffff',
                                  }}
                                >
                                  เข้าเกม
                                </div>
                              </a>

                              {/* <div className="text-white mt-5">
                              <a
                                className="cursor-pointer"
                                onClick={() => clickContact(val)}
                                alt="contact admin"
                              >
                                ติดต่อแอดมิน
                              </a>
                            </div> */}
                            </div>
                          </div>
                        </span>
                      ) : null}
                    </div>
                  </div>
                </Carousel.Item>
              ))
            : null}
        </Carousel>
      ) : null}
      <div></div>
    </>
  );
};

export default GameSlider;
