import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import '@fortawesome/fontawesome-free/css/all.min.css';
import { observer } from 'mobx-react-lite';

import Login from 'views/Login.js';
import Games from 'views/Games.js';
import Camps from 'views/Camps.js';
import BonusTime from 'views/BonusTime';
import AuthStore from 'stores/AuthStore';
import NotFound from 'views/NotFound';
import Logo from 'components/Logo';
import PlaySound from 'components/PlaySound';


import { config } from 'config';

import './assets/styles/main.css';
import './assets/styles/app.scss';
import { http } from 'helpers/http';

import { disableViewCode } from 'helpers/disable';
import { disableRightClick } from 'helpers/disable';
import bgSound from './assets/sound/rocket_v9 _18.mp3';
const PrivateRoute = observer(({ component: Component, role, ...rest }) => {
  const auth = useContext(AuthStore);
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          auth.isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    </>
  );
});

const App = observer((props) => {
  const authStore = useContext(AuthStore);
  const [isMouseEnter, setIsMouseEnter] = useState(false);


  const checkTimeout = () => {
    setTimeout(() => {
      http
        .post('/play/activate', {
          code: authStore.token,
        })
        .then(({ data }) => {
          if (data) {
            const token = data.data.code;
            const user = data.data.user;
            this.setToken(token, data.data.timeout_at);
            this.setUser({
              name: user,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 403) {
              authStore.logout();
            }
          }
        });
    }, 3000);
  };

  useEffect(() => {
    if (authStore.isLoggedIn) {
      checkTimeout();
      setInterval(() => {
        checkTimeout();
      }, 300000);
    }

    if (process.env.NODE_ENV === '' || process.env.NODE_ENV === 'production') {
      disableViewCode();
      disableRightClick();
    }
  }, []);

  useEffect(() => {
    setIsMouseEnter(true);
  });

  return (
    <>
      <div
        style={{
          padding: 0,
          margin: 0,
          height: '100%',
        }}
        onMouseEnter={() => {}}
      >
        <BrowserRouter basename={config.basePath} hashType='hashbang'>
          <div className='main-container'>
            <div className='main content'>
              {/* <UifxSound/> */}
           
              {authStore.isLoggedIn ? (
                <>
                  <Logo backTo='/camps' />
                </>
              ) : null}
              <div>
                <Switch>
                  <PrivateRoute exact path='/' component={Camps} />
                  <Route path='/login' component={Login} />
                  <PrivateRoute path='/landing' component={Camps} />
                  <PrivateRoute path='/camps/:campId/games' component={Games} />
                  <PrivateRoute path='/camps' component={Camps} />
                  <PrivateRoute path='/games' component={Games} />
                  <PrivateRoute path='/bonustime' component={BonusTime} />
                  <Route path='*' component={NotFound} />
                </Switch>
              </div>
              <PlaySound/>
            </div>
          </div>
        </BrowserRouter>
      </div>
    </>
  );
});

ReactDOM.render(<App />, document.getElementById('root'));
