import { config } from "config";
import React from "react";

export default function Footer({ store }) {
  return (
    <footer className="relative p-1 clear-both">
      <div className="container mx-auto">
        <div className="text-center">
          <div className="text-sm text-gray-200 font-semibold py-1">
            Copyright © {new Date().getFullYear()} / Rocket Original
            <br />v {config.version}
          </div>
        </div>
      </div>
    </footer>
  );
}
