import React, { createContext } from "react";
import {
  autorun,
  makeObservable,
  observable,
  set,
  computed,
  action,
  toJS,
} from "mobx";
import {
  addHours,
  fromUnixTime,
  getUnixTime,
  format,
  differenceInMinutes,
  differenceInSeconds,
  addMinutes,
  differenceInHours,
  addSeconds,
} from "date-fns";
import { th } from "date-fns/esm/locale";
import { formatDistance } from "date-fns/esm";
import { http } from "./../helpers/http";
import { useNavigate } from "react-router-dom";
export function autoSave(_this, name) {
  const storedJson = localStorage.getItem(name);
  if (storedJson) {
    set(_this, JSON.parse(storedJson));
  }
  autorun(() => {
    const value = toJS(_this);
    localStorage.setItem(name, JSON.stringify(value));
  });
}

export class Auth {
  authToken = "";
  authUser = "";
  authTimeout = "";
  timeoutInMinutes = "";
  timeoutInSeconds = "";
  timeoutInHours = "";
  messageError = "";

  constructor() {
    makeObservable(this, {
      authToken: observable,
      authUser: observable,
      authTimeout: observable,
      timeoutInMinutes: observable,
      timeoutInSeconds: observable,
      timeoutInHours: observable,
      messageError: observable,
      user: computed,
      token: computed,
      timeout: computed,
      setToken: action,
      logout: action,
      showTimeout: computed,
      setTimeoutDistance: action,
      updateToken: action,
      updateUser: action,
    });

    autorun(() => {
      //const token = localStorage.getItem("nnToken");
      // const user = localStorage.getItem('nnUser');
      // const timeout = localStorage.getItem('nnTimeout');
      this.updateToken(this.token);
      //this.authToken = token

      if (
        this.timeout !== null &&
        this.timeout !== undefined &&
        this.timeout >= 0
      ) {
        setTimeout(() => {
          setInterval(() => {
            this.setTimeoutDistance(this.timeout);
          }, 1000);
        }, 0);
      } else {
        this.setTimeoutDistance(0);
      }
    });
  }

  get isLoggedIn() {
    return !!this.authToken;
  }

  get token() {
    return this.authToken || localStorage.getItem("nnToken");
  }
  get user() {
    return this.authUser || JSON.parse(localStorage.getItem("nnUser"));
  }
  get timeout() {
    return this.authTimeout || localStorage.getItem("nnTimeout");
  }

  get showTimeout() {
    if (this.timeout) {
      return format(fromUnixTime(this.timeout), "pp", {
        locale: th,
      });
    }
    return "";
  }
  get showTimeoutCountdown() {
    if (this.timeout) {
      var helperDate = addSeconds(new Date(0), this.timeoutInSeconds);
      return format(helperDate, "mm:ss นาที");
    }
    return "";
  }

  get errMessage() {
    return this.messageError;
  }
  setTimeout(time) {
    this.authTimeout = time;
    localStorage.setItem("nnTimeout", time);
  }

  setTimeoutDistance(time) {
    if (time !== undefined && time !== null) {
      const minutes = formatDistance(fromUnixTime(time), new Date(), {
        locale: th,
        includeSeconds: true,
      });
      const diffMinutes = differenceInMinutes(fromUnixTime(time), new Date());
      const diffSeconds = differenceInSeconds(fromUnixTime(time), new Date());
      const diffHours = formatDistance(fromUnixTime(time), new Date(), {
        locale: th,
      });
      if (diffMinutes > 0 || diffSeconds > 0) {
        this.timeoutInMinutes = diffMinutes;
        this.timeoutInSeconds = diffSeconds;
        this.timeoutInHours = diffHours;
      }
      // console.log("second", diffSeconds);
      if (diffSeconds <= 0) {
        this.logout();
      }
    }
  }

  setToken(token, timeoutAt) {
    this.authTimeout = "";
    this.authToken = "";
    localStorage.removeItem("nnTimeout");
    localStorage.setItem("nnToken", token);
    const timeoutByHours = addHours(new Date(), 1);
    const timeoutByMinutes = addMinutes(new Date(), 1);
    const _timeout = new Date(timeoutAt);
    const timeout = getUnixTime(_timeout);
    localStorage.setItem("nnTimeout", timeout);

    this.authToken = token;
    this.authTimeout = timeout;
    localStorage.setItem("timeout", _timeout);
  }
  setUser(data) {
    this.authUser = data;
    localStorage.setItem("nnUser", JSON.stringify(data));
  }
  updateToken(token) {
    this.authToken = token;
  }
  updateUser(data) {
    this.authUser = data;
  }
  logout() {
    if (this.isLoggedIn) {
      this.authToken = "";
      this.authUser = "";
      this.authTimeout = "";
      localStorage.removeItem("nnUser");
      localStorage.removeItem("nnToken");
      localStorage.removeItem("nnTimeout");
      localStorage.removeItem("timeout");
    }
  }
}

export default createContext(new Auth());
