const node_env = process.env.NODE_ENV;

const Config = {
  version: "1.0.1",
  appName: "rocket",
  apiUrl:
    node_env && node_env !== "development"
      ? "https://office.rocket007.com/api"
      : "http://127.0.0.1:8000/api",
  basePath: "/",
  linkPlayGame: "https://auto.ry289.net/login",
  linkLine: "https://bit.ly/3q8IBra",
};

export const config = Config;
