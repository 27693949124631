import React from 'react';
import { useEffect, useState } from 'react';
import bgSound from './../assets/sound/rocket_v9 _18.mp3';
import Sound from 'react-sound';


const PlaySound = (props) => {
  const [playStatus, setPlayStatus] = useState(false);
  const [volume, setVolume] = useState(10);


  useEffect(() => {
    document.addEventListener('click', () => {
      console.log('click')
      setPlayStatus(true);
    });
    document.addEventListener('dblclick', () => {
     
    });
  }, []);
  return (
    <>
      <Sound
        url={bgSound}
        playStatus={playStatus ? Sound.status.PLAYING : Sound.status.PAUSED}
        loop={true}
        volume={volume}
      />
    </>
  );
};

export default PlaySound;
